import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  Outlet,
  useNavigation,
  redirect,
} from 'react-router-dom';
import Error from './pages/error';
import NotFound from './pages/not-found';
import RestrictedAccess from './components/restricted-access';
import PageLoaded from './components/page-loaded';
import LoaderSuspense from './components/loader-suspense';
import Background from './components/background';
import { enableShortcuts } from './utils/env';

const RootLayout = () => {
  const navigation = useNavigation();

  return (
    <>
      <Background />
      <PageLoaded />
      <Outlet />
      <LoaderSuspense show={navigation.state === 'loading'} />
    </>
  );
};

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<RootLayout />} errorElement={<Error />}>
      <Route path="/*" element={<NotFound />} />
      <Route path="/reset-password" lazy={() => import('./pages/auth/reset-password/page')} />
      <Route path="/sign-in" lazy={() => import('./pages/auth/sign-in/page')} />
      <Route path="/sign-up" lazy={() => import('./pages/auth/sign-up/page')} />
      <Route path="/box-choice" lazy={() => import('./pages/box-choice/page')} />
      <Route path="/wu/*" loader={() => redirect('/woodlock')} element={null} />
      <Route element={<RestrictedAccess />}>
        <Route path="/" lazy={() => import('./pages/catalog/page')} />
        <Route path="/catalog" lazy={() => import('./pages/catalog/page')} />
        {/* WOODLOCK */}
        <Route key="woodlock-boxes" path="/woodlock/boxes" lazy={() => import('./pages/woodlock/boxes/page')} />
        {...enableShortcuts
          ? [<Route path="/woodlock/debug-assets" lazy={() => import('./pages/woodlock/debug-assets/page')} />]
          : []}
        <Route key="woodlock-layout" lazy={() => import('./pages/woodlock/layout')}>
          <Route index path="/woodlock/:boxId?" lazy={() => import('./pages/woodlock/home/page')} />
          <Route path="/woodlock/:boxId/*" lazy={() => import('./pages/woodlock/home/page')} />
          <Route path="/woodlock/:boxId/history" lazy={() => import('./pages/woodlock/history/page')} />
          <Route path="/woodlock/:boxId/help" lazy={() => import('./pages/woodlock/help/page')} />
        </Route>
        {/* TAROT KILLER */}
        <Route path="/tarot/boxes" lazy={() => import('./pages/tarot-killer/boxes/page')} />
        <Route path="/tarot/" lazy={() => import('./pages/tarot-killer/layout')}>
          <Route path="/tarot/:boxId" lazy={() => import('./pages/tarot-killer/home/page')} />
          <Route path="/tarot/:boxId/*" lazy={() => import('./pages/tarot-killer/home/page')} />
          <Route path="/tarot/:boxId/history" lazy={() => import('./pages/tarot-killer/history/page')} />
          <Route path="/tarot/:boxId/help" lazy={() => import('./pages/tarot-killer/help/page')} />
        </Route>
        {/* CIAO BELLA */}
        <Route lazy={() => import('./pages/ciaobella/layout')}>
          <Route path="/ciao-bella" lazy={() => import('./pages/ciaobella/home/page')} />
          <Route path="/ciao-bella/help" lazy={() => import('./pages/ciaobella/help/page')} />
          <Route path="/ciao-bella/history" lazy={() => import('./pages/ciaobella/history/page')} />
          <Route path="/ciao-bella/fingerprints" lazy={() => import('./pages/ciaobella/minigames/fingerprints/page')} />
          <Route
            path="/ciao-bella/facial-composite"
            lazy={() => import('./pages/ciaobella/minigames/facial-composite/page')}
          />
          <Route
            path="/ciao-bella/neighbourhood-investigation"
            lazy={() => import('./pages/ciaobella/minigames/neighbourhood-investigation/page')}
          />
          <Route path="/ciao-bella/gang-hideout" lazy={() => import('./pages/ciaobella/minigames/gang-hideout/page')} />
          <Route path="/ciao-bella/camera-hack" lazy={() => import('./pages/ciaobella/minigames/camera-hack/page')} />
          <Route
            path="/ciao-bella/lonewood-flat"
            lazy={() => import('./pages/ciaobella/minigames/lonewood-flat/page')}
          />
          <Route path="/ciao-bella/safe" lazy={() => import('./pages/ciaobella/minigames/safe/page')} />
          <Route
            path="/ciao-bella/a1d5sqlcn34vpalx"
            lazy={() => import('./pages/ciaobella/minigames/a1d5sqlcn34vpalx/page')}
          />
        </Route>
        {/* BEAURECUEIL */}
        <Route lazy={() => import('./pages/beaurecueil/layout')}>
          <Route path="/beaurecueil" lazy={() => import('./pages/beaurecueil/home/page')} />
          <Route path="/beaurecueil/history" lazy={() => import('./pages/beaurecueil/history/page')} />
          <Route path="/beaurecueil/help" lazy={() => import('./pages/beaurecueil/help/page')} />
          <Route path="/beaurecueil/explorations/hut" lazy={() => import('./pages/beaurecueil/minigames/hut/page')} />
          <Route path="/beaurecueil/polygraph" lazy={() => import('./pages/beaurecueil/minigames/polygraph/page')} />
          <Route
            path="/beaurecueil/explorations/passaour"
            lazy={() => import('./pages/beaurecueil/minigames/passaour/page')}
          />
          <Route path="/beaurecueil/flutes" lazy={() => import('./pages/beaurecueil/minigames/flutes/page')} />
          <Route
            path="/beaurecueil/end-interrogatory"
            lazy={() => import('./pages/beaurecueil/minigames/end-interrogatory/page')}
          />
        </Route>
        {/* ILLUSION */}
        <Route lazy={() => import('./pages/illusion/layout')}>
          <Route index path="/illusion" lazy={() => import('./pages/illusion/home/page')} />
          <Route path="/illusion/history" lazy={() => import('./pages/illusion/history/page')} />
          <Route path="/illusion/help" lazy={() => import('./pages/illusion/help/page')} />
          <Route path="/illusion/cipher" lazy={() => import('./pages/illusion/cipher/page')} />
          <Route
            path="/illusion/explorations/flat"
            lazy={() => import('./pages/illusion/minigames/exploration-flat/page')}
          />
          <Route path="/illusion/prison" lazy={() => import('./pages/illusion/minigames/prison/page')} />
          <Route
            path="/illusion/immigration-report"
            lazy={() => import('./pages/illusion/minigames/immigration-report/page')}
          />
        </Route>

        {/* ADMIN */}
        <Route path="/admin" lazy={() => import('./pages/admin/layout')}>
          <Route path="/admin" index lazy={() => import('./pages/admin/users')} />
          <Route path="/admin/users" lazy={() => import('./pages/admin/users')} />
          <Route path="/admin/users/:id" lazy={() => import('./pages/admin/users/[id]')} />
        </Route>

        <Route path="/credits/:gameId" lazy={() => import('./pages/credits/page')} />
        <Route path="/legales" lazy={() => import('./pages/legal/page')} />
        <Route path="/settings" lazy={() => import('./pages/settings/page')} />
      </Route>
    </Route>,
  ),
);

const Router = () => <RouterProvider router={router} />;

export default Router;
