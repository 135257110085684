import cover1 from '@/assets/img/Facing-episode1.jpg?lqip';
import cover2 from '@/assets/img/Facing-episode2.jpg?lqip';
import cover3 from '@/assets/img/Facing-box-3.jpg?lqip';
import { cdnUrl } from '@/utils/env';
import { Episode } from '@/types/episode';

const boxes = [
  {
    name: 'Box 1',
    id: 1,
    status: 'open',
    cover: cover1,
    music: `${cdnUrl}sounds/musiques-db-s2-nappe-1.wav`,
  },
  {
    name: 'Box 2',
    id: 2,
    status: 'closed',
    cover: cover2,
    music: `${cdnUrl}sounds/musiques-db-s2-nappe-2.wav`,
  },
  {
    name: 'Box 3',
    id: 3,
    status: 'closed',
    cover: cover3,
    music: `${cdnUrl}sounds/musiques-db-s2-nappe-3.wav`,
  },
] as const satisfies Episode[];

export default boxes;
