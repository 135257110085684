import { localStorageKeys } from './local-storage';

const queryKeys = {
  progress: (appId: string, userId: string | null | undefined) => ['progress', appId, userId] as const,
  user: ({ id }: { id?: string } = {}) => ['user', id || localStorage.getItem(localStorageKeys.token)] as const,
  i18nextLanguage: () => ['i18next-language', localStorage.getItem(localStorageKeys.token)] as const,

  // Admin
  users: ({ search, connected }: { search: string; connected: boolean }) => ['users', { search, connected }],
  admins: () => ['admins'],
  connectedUsers: () => ['connected-users'],
};

export default queryKeys;
